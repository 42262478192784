import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endOfDay, format, startOfDay, startOfMonth } from "date-fns";

import { IAssetUsageRecordsState } from "../shared/interfaces/asset-usage-records-state";
import { AssetUsageRecord } from "../shared/models/asset-usage-record";
import { assetUsageRecordsService } from "../shared/services/asset-usage-records.service";
import { addNotificationAction } from "../../../modules/notifications";
import { NotificationType } from "../../../modules/notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../../modules/notifications/shared/enums/notification-severity";
import { IAssetUsageRecordDto } from "../shared/dtos/asset-usage-record.dto";
import { TAssetUsageFilter } from "../shared/interfaces/asset-usage-filter";
import { getSelected } from "./asset-usage-records.selector";
import { RootState } from "../../../store-types";
import { AssetUsageRecordStatus } from "../shared/enums/asset-usage-record-status";
// import { TEkoCropApiFilter } from "../../../modules/filter/shared/utils/eko-crop-filter-adapter";
import { getConfigurationState } from "../shared/utils/get-configuration-state";
import { verificationService } from "../../../modules/verification/shared/services/verification.service";
import { AssetUsageRecordListSchema } from "../shared/dtos/asset-usage-record.schema";
//import {createApi} from "@reduxjs/toolkit/query/react";
//import {baseQuery} from "../../../shared/utils/rtkq";
import { ISO8601_DATE_FORMAT } from "../../../constant";

const initialFilterState = {
  fromDate: format(startOfDay(startOfMonth(new Date())), ISO8601_DATE_FORMAT),
  toDate: format(endOfDay(new Date()), ISO8601_DATE_FORMAT),
  farmIds: [],
  statusIds: [],
  techOperationGroups: [],
  techOperationSubGroups: [],
  cropTypeIds: [],
  assetCategories: [],
  farmlandIds: [],
  materiallyAccountableEmployees: [],
};

const initialState: IAssetUsageRecordsState = {
  details: null,
  isLoading: false,
  isFilterOpen: false,
  list: [],
  listCount: 0,
  filter: initialFilterState,
  configuration: getConfigurationState(),
  selected: [],
};

export const ASSET_USAGE_RECORDS_MODULE_NAME = "assetUsageRecords";

export const assetUsageRecordsSlice = createSlice({
  name: ASSET_USAGE_RECORDS_MODULE_NAME,
  initialState,
  reducers: {
    setDetails(state, action: PayloadAction<AssetUsageRecord | null>): void {
      state.details = action.payload;
    },
    toggleIsFilterOpen(state): void {
      state.isFilterOpen = !state.isFilterOpen;
    },
    setIsFilterOpen(state, action: PayloadAction<boolean>): void {
      state.isFilterOpen = action.payload;
    },
    setListAction(state, action: PayloadAction<AssetUsageRecord[]>): void {
      state.list = action.payload;
    },
    setListCountAction(state, action: PayloadAction<number>): void {
      state.listCount = action.payload;
    },
    setSelectedAction(state, action: PayloadAction<AssetUsageRecord[]>): void {
      state.selected = action.payload;
    },
    addSelectedAction(state, action: PayloadAction<AssetUsageRecord[]>): void {
      const itemsToAdd = action.payload;
      const existedIds = state.selected.map((item) => item.id);

      for (let i = 0; i < itemsToAdd.length; i++) {
        if (existedIds.includes(itemsToAdd[i].id)) {
          continue;
        }
        state.selected.push(itemsToAdd[i]);
      }
    },
    removeSelectedAction(state, action: PayloadAction<AssetUsageRecord[]>): void {
      const idsToRemove = action.payload.map((item) => item.id);

      for (let i = state.selected.length - 1; i >= 0; i--) {
        if (!idsToRemove.includes(state.selected[i].id)) {
          continue;
        }
        state.selected.splice(i, 1);
      }
    },
    toggleSelectedAction(state, action: PayloadAction<AssetUsageRecord[]>): void {
      const selectedItems = new Map<string, AssetUsageRecord>(
        action.payload.map((obj) => [obj.id, obj])
      );

      // remove already existed items
      for (let i = state.selected.length - 1; i >= 0; i--) {
        const existingId = state.selected[i].id;
        if (selectedItems.has(existingId)) {
          state.selected.splice(i, 1);
          selectedItems.delete(existingId);
        }
      }
      // add new ones
      state.selected.push(...Array.from(selectedItems.values()));
    },
    setFilterAction(state, action: PayloadAction<TAssetUsageFilter>): void {
      state.filter = action.payload;
    },
    resetFilterAction(state): void {
      state.filter = initialFilterState;
    },
    resetSelectedAction(state): void {
      state.selected = initialState.selected;
    },
    toggleVisibleColumn(state, action: PayloadAction<keyof AssetUsageRecord>): void {
      const value = action.payload;
      const { configurable, columns, selected } = state.configuration;
      if (!configurable.includes(value)) {
        return;
      }

      const item = columns.find((column) => column.id === value);
      if (!item) {
        return;
      }

      const index = selected.indexOf(value);
      if (index !== -1) {
        selected.splice(index, 1);
      } else {
        selected.push(value);
      }

      state.configuration.selected = selected;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAssetUsageRecordsAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchAssetUsageRecordsAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchAssetUsageRecordsAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const assetUsageRecordsReducer = assetUsageRecordsSlice.reducer;
export const {
  setDetails,
  setListAction,
  setSelectedAction,
  toggleIsFilterOpen,
  setListCountAction,
  addSelectedAction,
  removeSelectedAction,
  toggleSelectedAction,
  setFilterAction,
  resetFilterAction,
  toggleVisibleColumn,
  resetSelectedAction,
} = assetUsageRecordsSlice.actions;

let abortController: AbortController | null = null;
export const fetchAssetUsageRecordsAction = createAsyncThunk<void, TAssetUsageFilter>(
  `${ASSET_USAGE_RECORDS_MODULE_NAME}/fetchAssetUsageRecordsAction`,
  async (filter, { dispatch }) => {
    // prevent multiple calls
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();

    let dtos: IAssetUsageRecordDto[];
    let count: number;
    try {
      [dtos, count] = await Promise.all([
        assetUsageRecordsService.list(filter, { signal: abortController.signal }).then(
          verificationService.check({
            cacheId: "AssetUsageRecordListSchema",
            schema: AssetUsageRecordListSchema,
            message: "Невалидный ответ сервера (AssetUsageRecord)",
          })
        ),
        assetUsageRecordsService.listCount(filter, { signal: abortController.signal }),
      ]);
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "AbortError") {
          return;
        }
      }
      dispatch(
        addNotificationAction({
          type: NotificationType.Snack,
          severity: NotificationSeverity.Error,
          message: "Ошибка получения актов списания",
          error,
        })
      );
      throw error;
    }
    abortController = null;

    const models = dtos.map((dto) => {
      const model = new AssetUsageRecord(dto.id);
      try {
        model.updateFromDto(dto);
      } catch (error) {
        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Warning,
            message: "Ошибка в модели акта списания",
            error,
          })
        );
      }
      return model;
    });

    dispatch(setListAction(models));
    dispatch(setListCountAction(count));
  }
);

export const prepareAssetsToSendAction = createAsyncThunk<
  boolean,
  void,
  { state: RootState }
>(
  `${ASSET_USAGE_RECORDS_MODULE_NAME}/prepareAssetsToSendAction`,
  async (_, { dispatch, getState }) => {
    const selectedItems = getSelected(getState());
    // TODO: extend validation filter
    // removed via https://tracker.yandex.ru/DEVELOPMENT-4014
    // .filter((item) =>
    //   [AssetUsageRecordStatus.Created, AssetUsageRecordStatus.Expired].includes(
    //     item.status
    //   )
    // );
    if (!selectedItems.length) {
      dispatch(
        addNotificationAction({
          type: NotificationType.Snack,
          severity: NotificationSeverity.Warning,
          message: "Доступных для списания записей не выбрано",
        })
      );
      return false;
    }

    // TODO: need to use a transaction when we try to patch models (group operations should use one request)
    const prepareAssetsToSendCb = async () => {
      let updateCount = 0;
      try {
        for (const item of selectedItems) {
          item.setStatus(AssetUsageRecordStatus.PreparedToSend);
        }
        await assetUsageRecordsService.prepareToSend(
          selectedItems.map((item) => item.id)
        );
        updateCount = selectedItems.length;

        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Success,
            message: `Подготовлено к отправке актов: ${updateCount}`,
          })
        );
      } catch (error) {
        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Error,
            message: `Возникла ошибка при попытке списания актов`,
            error,
          })
        );
        updateCount = 0;
      }

      // marker of changes (records updated or not)
      return !!updateCount;
    };

    return new Promise((resolve) => {
      dispatch(
        addNotificationAction({
          type: NotificationType.Confirm,
          severity: NotificationSeverity.Info,
          message:
            "Внимание! Будут списаны все ранее указанные активы (из агрегирующей таблицы)",
          cb: () => prepareAssetsToSendCb().then(resolve),
        })
      );
    });
  }
);

//TODO: need an integration with react query (list, count, filter) instead of using this store
// export const assetUsageRecordsApi = createApi({
//     reducerPath: 'api-asset-usage-records',
//     tagTypes: ['AssetUsageRecords'],
//     baseQuery,
//     endpoints: (build) => ({
//         assetUsageRecordList: build.query<AssetUsageRecord[], TAssetUsageFilter>({
//             query: (filter)=>({
//                 url: 'AssetUsageRecords',
//                 method: 'GET',
//                 searchParams: { ...filter },
//             }),
//             providesTags: ['AssetUsageRecords'],
//             onCacheEntryAdded: (_arg, {dispatch, getState}): Promise<void> | void =>  {
//                 const state = getState();
//                 console.log(state);
//                 let dtos: IAssetUsageRecordDto[]=[];
//                 try {
//                     verificationService.check({
//                         cacheId: "AssetUsageRecordListSchema",
//                         schema: AssetUsageRecordListSchema,
//                         message: "Невалидный ответ сервера (AssetUsageRecord)",
//                     })(dtos)
//                 } catch (error) {
//                     dispatch(
//                         addNotificationAction({
//                             type: NotificationType.Snack,
//                             severity: NotificationSeverity.Error,
//                             message: "Ошибка получения актов списания",
//                             error,
//                         })
//                     );
//                     throw error;
//                 }
//
//             },
//             transformResponse: (data: IAssetUsageRecordDto[]) => verificationService
//                 .check({
//                     cacheId: "AssetUsageRecordListSchema",
//                     schema: AssetUsageRecordListSchema,
//                     message: "Невалидный ответ сервера (AssetUsageRecord)",
//                 })(data)
//                 .map((dto) => {
//                     const model = new AssetUsageRecord(dto.id);
//                     model.updateFromDto(dto);
//                     return model;
//                 })
//         })
//     })
// })
//
// export const {useAssetUsageRecordListQuery} = assetUsageRecordsApi;
