import { enableMapSet } from "immer";
import { AnyAction, ThunkAction, ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { dynamicReducers } from "./reducers";
import { RootState } from "./store-types";
import { techOpsApiSlice } from "./pages/tech-operations/store/techop.rtkq";
import { techOpsRelatedApiSlice } from "./pages/tech-operations/store/techop-related.rtkq";
import { farmLandsApiSlice } from "./pages/fields/store/farm-lands.rtkq";
import { currentFarmLandsApiSlice } from "./modules/farm-lands/current-farm-lands.rtkq";
import { techAssetsApiSlice } from "./modules/tech-assets/store/techop-assets.rtkq";
import { materiallyAccountableEmployeesSlice } from "./modules/employees/store";
import { assetStocksSlice } from "./modules/asset-stocks/store";
import { observationsApiSlice } from "./pages/observations/store/observations.rtkq";

export const setupStore /* TODO : ToolkitStore<RootState>*/ = () =>
  configureStore({
    reducer: {
      ...dynamicReducers,
      [techOpsApiSlice.reducerPath]: techOpsApiSlice.reducer,
      [techOpsRelatedApiSlice.reducerPath]: techOpsRelatedApiSlice.reducer,
      [farmLandsApiSlice.reducerPath]: farmLandsApiSlice.reducer,
      [currentFarmLandsApiSlice.reducerPath]: currentFarmLandsApiSlice.reducer,
      [techAssetsApiSlice.reducerPath]: techAssetsApiSlice.reducer,
      [materiallyAccountableEmployeesSlice.reducerPath]:
        materiallyAccountableEmployeesSlice.reducer,
      [assetStocksSlice.reducerPath]: assetStocksSlice.reducer,
      [observationsApiSlice.reducerPath]: observationsApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // TODO: think about custom availability
        immutableCheck: false, // sometimes enable it for checking
      }).concat([
        techOpsApiSlice.middleware,
        techOpsRelatedApiSlice.middleware,
        farmLandsApiSlice.middleware,
        currentFarmLandsApiSlice.middleware,
        techAssetsApiSlice.middleware,
        materiallyAccountableEmployeesSlice.middleware,
        assetStocksSlice.middleware,
        observationsApiSlice.middleware,
      ]),
  });

export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

// Export a hook that can be reused to resolve types
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

enableMapSet();
